import React, { useContext, useState } from 'react';
import { Card, Col, Row, Spin, message, Badge } from 'antd';
import {
  PlusOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  DisconnectOutlined
} from '@ant-design/icons';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RatingsProviderTexts } from '../utils/texts';
import { groupArrayByKey, deepCopy } from '../../helper/array';
import { PublicTrustBadgeInterface } from '../../Funnel/components/SocialProofSettingsBuilder';
import { ConnectSettingsMessages } from '../../config/messages';
import Zapier from '../components/Zapier';
import GoogleConnector from '../../Login/components/GoogleConnector';
import Tooltip from 'antd/es/tooltip';
import ConnectSettingsChanger from '../components/ConnectSettingsChanger';
import PSL from 'psl';
import { TourContext } from '../../Tour/util/TourProvider';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import BlockedFeatureOverlay from '../../SharedUI/components/BlockedFeatureOverlay';
import { getEnvironment } from '../../helper/environment';
import { useAppSelector } from '../../redux/hooks';
import moment from 'moment';
import CustomDomains from './CustomDomains';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../UI/utils/mixpanel';
import { EDIT_CONTENT_TYPE, useEditResourceContext } from '../context/ResourceEditContext';
import ConnectWebhooks from './ConnectWebhooks';
import { defaultStringFieldValidation } from '../../UI/utils/formValidation';
import BannerTop from './BannerTop';
import { CONNECT_CONTENT_TYPE_NOT_EDITABLE, connectCardTitlesAndCategories } from '../utils/types';
import { useSubscriptionInfo } from '../../UI/redux/userSlice';
import GoogleCalendarModal from '../components/GoogleCalendarModal';

const builderV2LaunchDate = '27-10-2022';

export const GET_CONNECT_SETTINGS = gql`
  query {
    getThirdPartyTrackingSettings(getAll: true) {
      id
      provider
      enabled
      providerId
      funnelIds
      accessToken
      label
    }
    getRatingProviderSettings(getAll: true) {
      id
      provider
      profileURL
      activated
      funnelIds
    }
    getPublicTrustBadge {
      id
      url
      title
    }
    getUser {
      connectedToGoogle
      provider
    }
  }
`;

const SET_RATING_PROVIDER = gql`
  mutation setRatingsProviderSettings(
    $input: [RatingProviderSettingsData!]!
    $idsToDelete: [Int!]
  ) {
    setRatingProviderSettings(input: $input, idsToDelete: $idsToDelete) {
      id
    }
  }
`;

const DISCONNECT_GOOGLE_ACCOUNT = gql`
  mutation disconnectGoogleAccount {
    disconnectGoogleAccount {
      success
    }
  }
`;

export const SET_PIXEL_SETTINGS = gql`
  mutation setThirdPartyTrackingSettings(
    $input: [ThirdPartyTrackingSettingsData!]
    $idsToDelete: [Int!]
  ) {
    setThirdPartyTrackingSettings(input: $input, idsToDelete: $idsToDelete) {
      id
    }
  }
`;

const SET_DOMAIN_DATA = gql`
  mutation setDomains($input: [DomainData!]!, $idsToDelete: [Int!]) {
    setDomains(input: $input, idsToDelete: $idsToDelete) {
      id
    }
  }
`;

interface DomainCountInterface {
  [key: string]: { realDomainCount: number; ourDomainCount: number };
}

function Connect() {
  const [visible, setVisible] = useState(false);
  const [googleInfoModalVisibility, setGoogleInfoModalVisibility] = useState(false);
  const user = useAppSelector(state => state.user.data);
  const shouldShowTrustBadges = moment(moment(user.createdAt).format()).isBefore(
    moment(builderV2LaunchDate, 'DDMMYYYY')
  );

  const { loading: getConnctSettingsLoading, data, refetch: refetchConnectSettings } = useQuery(
    GET_CONNECT_SETTINGS,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const { resourceEditSocketLoading } = useEditResourceContext();

  const { isDev } = getEnvironment();
  const isAccessAllowed = true;
  /* Enabled for the event */
  /*     !isDev ||
    !!localStorage.getItem('meetovoConnectAccessAllowed') ||
    window.location.host.includes('localhost'); */

  const { reloadTours } = useContext(TourContext);

  const refetchOptions = {
    refetchQueries: [{ query: GET_CONNECT_SETTINGS }],
    awaitRefetchQueries: true
  };

  const [setRatingsProvider, { loading: setRatingsProviderLoading }] = useMutation(
    SET_RATING_PROVIDER,
    refetchOptions
  );

  const [disconnectGoogleAccount, { loading: disconnectGoogleAccountLoading }] = useMutation(
    DISCONNECT_GOOGLE_ACCOUNT,
    refetchOptions
  );

  const [
    setThirdPartyTrackingSettings,
    { loading: setThirdPartyTrackingSettingsLoading }
  ] = useMutation(SET_PIXEL_SETTINGS, refetchOptions);

  const [setDomains, { loading: setDomainsLoading }] = useMutation(SET_DOMAIN_DATA, refetchOptions);

  const loading =
    getConnctSettingsLoading ||
    setThirdPartyTrackingSettingsLoading ||
    disconnectGoogleAccountLoading ||
    setRatingsProviderLoading ||
    setDomainsLoading ||
    resourceEditSocketLoading;

  const { connectedToGoogle, provider } = data?.getUser || {
    connectedToGoogle: false,
    provider: 'EMAIL'
  };
  const rawThirdPartyTrackingSettings = data?.getThirdPartyTrackingSettings || [];
  const rawRatingProviderSettings = data?.getRatingProviderSettings || [];
  const subscriptionInfo = useSubscriptionInfo();
  const maxDomains = subscriptionInfo?.maxDomains || 0;

  let publicTrustBadges = data?.getPublicTrustBadge || [];

  const ratingProviderSettings = groupArrayByKey(rawRatingProviderSettings, 'provider');
  const thirdPartyTrackingSettings = groupArrayByKey(rawThirdPartyTrackingSettings, 'provider');

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const handleSubmitRatingsProvider = ({ submissionData, idsToDelete }: any) => {
    setRatingsProvider({ variables: { input: submissionData, idsToDelete } })
      .then((res: any) => {
        if (res.data.setRatingProviderSettings) {
          message.success(ConnectSettingsMessages.setRatingsProviderSettingsSuccess);
        } else {
          throw new Error();
        }
      })
      .catch(e => {
        message.error(ConnectSettingsMessages.setRatingsProviderSettingsError);
      });
  };

  const handleSubmitTrackingProvider = ({ submissionData, idsToDelete }: any, resource: string) => {
    setThirdPartyTrackingSettings({ variables: { input: submissionData, idsToDelete } })
      .then((res: any) => {
        if (res.data.setThirdPartyTrackingSettings) {
          message.success(ConnectSettingsMessages.setThirdPartyTrackingSettingsSuccess);
          mixpanel.track(mixpanelEvents.THIRD_PARTY_TRACKING, {
            provider: resource
          });
          const shouldReloadTours = submissionData
            .map((dt: any) => dt.provider as string)
            .some(
              (provName: string) =>
                provName === 'facebook-pixel' || provName === 'google-tag-manager'
            );

          // Only reload tour if pixel or tag manager updated or created
          if (shouldReloadTours) {
            reloadTours();
          }
        } else {
          throw new Error();
        }
      })
      .catch(e => {
        message.error(ConnectSettingsMessages.setThirdPartyTrackingSettingsError);
      });
  };

  const handleDomainSubmitData = ({ submissionData, idsToDelete }: any) => {
    const gqlData = submissionData.map((data: any) => {
      const { name, funnelIds, id } = data;

      return {
        id,
        name,
        funnelId: funnelIds[0]
      };
    });

    try {
      setDomains({ variables: { input: gqlData, idsToDelete } })
        .then((res: any) => {
          if (res.data.setDomains) {
            message.success(ConnectSettingsMessages.setDomainsSuccess);
            try {
              reloadTours();
            } catch (error) {}
          } else {
            throw new Error();
          }
        })
        .catch(e => {
          message.error(ConnectSettingsMessages.setDomainsError);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDisconnectGoogleAccount = () => {
    disconnectGoogleAccount()
      .then(() => {
        message.success(ConnectSettingsMessages.disconnectGoogleAccountSuccess, 10);
      })
      .catch(() => message.error(ConnectSettingsMessages.couldNotDisconnectGoogleAccount));
  };

  const validateDomainCount = (values: string[]) => {
    const duplicateDomain = values.some((domain, index) =>
      values.some((domain2, index2) => domain2 === domain && index != index2)
    );
    if (duplicateDomain)
      return {
        hasError: true,
        errorMessage: ConnectSettingsMessages.duplicateDomain
      };

    const domainCountData = values.reduce((acc, name) => {
      const parsedDomain = PSL.parse(name);
      if (parsedDomain.error) return acc;

      const rootDomain = parsedDomain.domain;
      if (!rootDomain) return acc;

      if (!acc[rootDomain]) acc[rootDomain] = { realDomainCount: 0, ourDomainCount: 0 };

      acc[rootDomain].realDomainCount += 1;

      acc[rootDomain].ourDomainCount = Math.ceil(acc[rootDomain].realDomainCount / 5);

      return acc;
    }, {} as DomainCountInterface);

    const domainCount = Object.keys(domainCountData).reduce(
      (acc, key) => acc + domainCountData[key].ourDomainCount,
      0
    );

    if (domainCount > maxDomains) {
      return {
        hasError: true,
        errorMessage: ConnectSettingsMessages.notEnaugthDomains
      };
    }

    return {
      hasError: false,
      errorMessage: ''
    };
  };

  const googleConnectHeader = loading ? (
    <DefaultSmallLoader loading={loading} />
  ) : connectedToGoogle ? (
    'Verbunden'
  ) : (
    'Nicht verbunden'
  );

  return (
    <div
      className={`connect ${!isAccessAllowed ? ' blocked-feature-overlay__overlay-visible' : ''}`}
    >
      {!isAccessAllowed && (
        <BlockedFeatureOverlay
          imageStyle={{
            height: '180px',
            marginBottom: '0.5em'
          }}
          title="Dieser Bereich ist gesperrt"
          description="Während der Beta können keine externen Dienste angebunden werden. Diese sind nur für die Live-Version verfügbar."
        />
      )}
      {!isAccessAllowed && <div className="blocked-feature-overlay__overlay-black-layer" />}

      <BannerTop
        text={
          <>
            Integriere +2.000 Tools, <br /> die du bereits nutzt
          </>
        }
        subText={
          'Es gibt direkte Integrationen zu Google-Calendar, Facebook-Pixel und Google-Tag-Manager. Mit Zapier und Make (früher: Integromat) kannst du mehr als 2000 weitere Tools verbinden.'
        }
        imageUrl={'/dashboard/connect/connect__banner-graphic.jpg'}
        imageUrlMobile={`/dashboard/connect/connect__banner-graphic__mobile.jpg`}
      />
      <Row gutter={30}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <CustomDomains />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Tooltip
            title={
              <span>
                <strong>Status: </strong> {googleConnectHeader}
              </span>
            }
            visible={visible}
            onVisibleChange={handleVisibleChange}
          >
            <div onClick={handleVisibleChange} className="card-with-hover">
              <Card
                hoverable
                className="card-container"
                cover={
                  <div
                    className={`card-header google-status ${
                      connectedToGoogle ? 'google-status__connected' : 'google-status__disconnected'
                    }`}
                  >
                    {!connectedToGoogle ? (
                      <GoogleConnector
                        connectExistingAccount
                        onSuccess={() => refetchConnectSettings()}
                        renderTrigger={({ loading, onClick }) =>
                          loading ? (
                            <DefaultSmallLoader loading={true} />
                          ) : (
                            <PlusOutlined
                              onClick={e => {
                                e.stopPropagation();
                                onClick();
                              }}
                            />
                          )
                        }
                      />
                    ) : provider === 'EMAIL' && connectedToGoogle ? (
                      <Tooltip title="Verbindung aufheben">
                        <DisconnectOutlined
                          onClick={e => {
                            e.stopPropagation();

                            !disconnectGoogleAccountLoading
                              ? handleDisconnectGoogleAccount()
                              : null;
                          }}
                          style={{ color: 'red', marginLeft: '10px' }}
                        />
                      </Tooltip>
                    ) : null}
                    <img src={process.env.PUBLIC_URL + '/connect/google-calendar-icon.png'} />
                  </div>
                }
              >
                <Card.Meta
                  title={
                    connectCardTitlesAndCategories[
                      CONNECT_CONTENT_TYPE_NOT_EDITABLE.GOOGLE_CALENDAR
                    ].title
                  }
                  className="card-title"
                />
                <div className="d-flex justify-content-between">
                  {
                    connectCardTitlesAndCategories[
                      CONNECT_CONTENT_TYPE_NOT_EDITABLE.GOOGLE_CALENDAR
                    ].category
                  }
                  <InfoCircleOutlined
                    style={{
                      fontSize: '20px'
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setGoogleInfoModalVisibility(true);
                    }}
                  />
                </div>
              </Card>
            </div>
          </Tooltip>
          <GoogleCalendarModal
            visible={googleInfoModalVisibility}
            setVisibility={setGoogleInfoModalVisibility}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <ConnectSettingsChanger
            resourceType={EDIT_CONTENT_TYPE.FACEBOOK_PIXELS}
            title={connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.FACEBOOK_PIXELS].title}
            logoFileName="facebook.png"
            loading={loading}
            refetchConnectSettings={refetchConnectSettings}
            searchable
            relevantProps={{
              value: 'providerId',
              valueLabel: 'Pixel-ID',
              valuePlaceholder: 'z.B. 123456789101112131415',
              state: 'enabled',
              customValue: 'label',
              customValuePlaceholder: 'z.B. Kunde XY ...',
              customValueLabel: 'Titel',
              customValueRules: [defaultStringFieldValidation[1]]
            }}
            settings={thirdPartyTrackingSettings['facebook-pixel']}
            emptySettings={{
              provider: 'facebook-pixel',
              providerId: '',
              enabled: true
            }}
            onSubmit={handleSubmitTrackingProvider}
          >
            <>
              <p>
                Die jeweiligen „Pixel-Events“ in deinem Funnel kannst du verwenden, um deine
                Kampagnen zu optimieren und auszuwerten. Je mehr Daten Facebook sammelt, desto
                besser werden die Ergebnisse deiner Kampagnen.
              </p>
              <p>Deine Events kannst du in jedem Funnel individuell einstellen.</p>
            </>
          </ConnectSettingsChanger>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <ConnectSettingsChanger
            resourceType={EDIT_CONTENT_TYPE.FACEBOOK_CONVERSATION_API}
            title={
              connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.FACEBOOK_CONVERSATION_API].title
            }
            logoFileName="facebook-conversions-api.png"
            searchable
            loading={loading}
            refetchConnectSettings={refetchConnectSettings}
            relevantProps={{
              value: 'accessToken',
              valueLabel: 'Access Token',
              valuePlaceholder: 'z.B. EAACJ5m8kZANYBAMlTw2JKrjn...',
              state: 'enabled',
              customValue: 'label',
              customValuePlaceholder: 'z.B. Kunde XYZ...',
              customValueLabel: 'Label',
              customValueRules: [defaultStringFieldValidation[1]]
            }}
            settings={thirdPartyTrackingSettings['facebook-conversion-api']}
            emptySettings={{
              provider: 'facebook-conversion-api',
              providerId: '',
              enabled: true,
              private: true
            }}
            disabled={!thirdPartyTrackingSettings['facebook-pixel']?.length}
            disabledMessage="Du musst erst deinen Facebook Pixel verbinden."
            onSubmit={handleSubmitTrackingProvider}
          >
            <>
              <p>
                Es werden ebenfalls die jeweiligen „Pixel-Events“ verwendet, die du in deinem Funnel
                hinterlegt hast. Die Conversions API muss immer mit deinem Pixel verbunden sein.
                Bitte achte darauf, dass der "Access Token" aus dem Facebook Business Manager in der
                Verbindung mit deinem bestehenden Pixel erstellt wurde.
              </p>
            </>
          </ConnectSettingsChanger>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <ConnectSettingsChanger
            resourceType={EDIT_CONTENT_TYPE.GOOGLE_TAG}
            title={connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.GOOGLE_TAG].title}
            logoFileName="google-tag-manager.png"
            loading={loading}
            refetchConnectSettings={refetchConnectSettings}
            searchable
            relevantProps={{
              value: 'providerId',
              valueLabel: 'Container-ID',
              valuePlaceholder: 'z.B. GTM-XXXXXXX',
              state: 'enabled',
              customValue: 'label',
              customValuePlaceholder: 'z.B. Kunde XYZ...',
              customValueLabel: 'Label',
              customValueRules: [defaultStringFieldValidation[1]]
            }}
            settings={thirdPartyTrackingSettings['google-tag-manager']}
            emptySettings={{
              provider: 'google-tag-manager',
              providerId: '',
              enabled: true
            }}
            onSubmit={handleSubmitTrackingProvider}
          >
            <>
              <p>
                Die jeweiligen „Tags“ in deinem Funnel kannst du verwenden, um deine Kampagnen zu
                optimieren und auszuwerten. Je mehr Daten Google sammelt, desto besser werden die
                Ergebnisse deiner Kampagnen.
              </p>
              <p>Deine Tags kannst du in jedem Funnel individuell einstellen.</p>
            </>
          </ConnectSettingsChanger>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <ConnectSettingsChanger
            resourceType={EDIT_CONTENT_TYPE.TIKTOK_PIXELS}
            title={connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.TIKTOK_PIXELS].title}
            logoFileName="tiktok.png"
            loading={loading}
            refetchConnectSettings={refetchConnectSettings}
            searchable
            relevantProps={{
              value: 'providerId',
              valueLabel: 'Pixel-ID',
              valuePlaceholder: 'z.B. XXXXXXXXX',
              state: 'enabled',
              customValue: 'label',
              customValuePlaceholder: 'z.B. Kunde XYZ...',
              customValueLabel: 'Label',
              customValueRules: [defaultStringFieldValidation[1]]
            }}
            settings={thirdPartyTrackingSettings['tiktok-pixel']}
            emptySettings={{
              provider: 'tiktok-pixel',
              providerId: '',
              enabled: true
            }}
            onSubmit={handleSubmitTrackingProvider}
          >
            <>
              <p>
                Die jeweiligen „Events“ in deinem Funnel kannst du verwenden, um deine Kampagnen zu
                optimieren und auszuwerten. Je mehr Daten TikTok sammelt, desto besser werden die
                Ergebnisse deiner Kampagnen.
              </p>
              <p>Deine Events kannst du in jedem Funnel individuell einstellen.</p>
            </>
          </ConnectSettingsChanger>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <ConnectSettingsChanger
            resourceType={EDIT_CONTENT_TYPE.LINKEDIN_TAG}
            title={connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.LINKEDIN_TAG].title}
            logoFileName="linkedin.png"
            loading={loading}
            refetchConnectSettings={refetchConnectSettings}
            searchable
            relevantProps={{
              value: 'providerId',
              valueLabel: 'Partner-ID',
              valuePlaceholder: 'z.B. XXXXXXXXX',
              state: 'enabled',
              customValue: 'label',
              customValuePlaceholder: 'z.B. Kunde XYZ...',
              customValueLabel: 'Label',
              customValueRules: [defaultStringFieldValidation[1]]
            }}
            settings={thirdPartyTrackingSettings['linkedin-event']}
            emptySettings={{
              provider: 'linkedin-event',
              providerId: '',
              enabled: true
            }}
            onSubmit={handleSubmitTrackingProvider}
          >
            <>
              <p>
                Die jeweiligen „Conversion-ID's“ in deinem Funnel kannst du verwenden, um deine
                Kampagnen zu optimieren und auszuwerten. Je mehr Daten LinkedIn sammelt, desto
                besser werden die Ergebnisse deiner Kampagnen.
              </p>
              <p>Deine Conversion-ID's kannst du in jedem Funnel individuell einstellen.</p>
            </>
          </ConnectSettingsChanger>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <ConnectWebhooks />
        </Col>

        {shouldShowTrustBadges &&
          deepCopy(publicTrustBadges)
            .reverse()
            .map(({ url, title }: PublicTrustBadgeInterface, i: number) => {
              const valuePlaceholder = `z.B. https://${
                title === 'ProvenExpert'
                  ? 'provenexpert.com/de-de/XXXXXX'
                  : 'de.trustpilot.com/review/XXXXXX'
              }`;

              return (
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <ConnectSettingsChanger
                    resourceType={EDIT_CONTENT_TYPE.FACEBOOK_PIXELS}
                    key={i}
                    title={title}
                    logoURL={url}
                    loading={loading}
                    searchable
                    refetchConnectSettings={refetchConnectSettings}
                    relevantProps={{
                      value: 'profileURL',
                      valueLabel: 'Profil-URL',
                      valueIsLink: true,
                      valuePlaceholder,
                      state: 'activated'
                    }}
                    settings={ratingProviderSettings[title]}
                    emptySettings={{
                      provider: title,
                      profileURL: '',
                      activated: true
                    }}
                    onSubmit={handleSubmitRatingsProvider}
                  >
                    {RatingsProviderTexts[title]}
                  </ConnectSettingsChanger>
                </Col>
              );
            })}
        <Zapier />
      </Row>
    </div>
  );
}

export default Connect;
