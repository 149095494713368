import { Col, Row, Slider } from 'antd';
import React, { PropsWithChildren } from 'react';
import { SliderProps, SliderValue } from 'antd/lib/slider';
import BuilderSettingsTitle from './BuilderSettingsTitle';
import { JOB_ATTRIBUTES_IDENTIFIER } from '../../AdJobBuilder/helper/AdJobHelper';
import { TARGET_GROUP_ELEMENTS } from '../generalSettings/TargetGroup/components/TargetGroupHelper';

interface RangeSliderProps extends PropsWithChildren<SliderProps> {
  handleChange: (
    value: number[] | SliderValue,
    identifier: JOB_ATTRIBUTES_IDENTIFIER | TARGET_GROUP_ELEMENTS
  ) => void;
  identifier: JOB_ATTRIBUTES_IDENTIFIER | TARGET_GROUP_ELEMENTS;
  title: string;
  value: any;
  range?: boolean;
}

export default function RangeSlider({
  handleChange,
  identifier,
  value,
  title,
  range,
  ...other
}: RangeSliderProps) {
  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle title={title} />
        <Slider
          range={range}
          onChange={value => {
            handleChange(value, identifier);
          }}
          value={value as SliderValue}
          {...other}
        />
      </Col>
    </Row>
  );
}
