import React, { useEffect, useState, Suspense } from 'react';
import { LoadingOutlined, CloseOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Layout, Menu, Alert, Button, Tooltip } from 'antd';
import {
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { UIMessages } from '../../config/messages';
import routes from '../routes';
import { useQuery } from '@apollo/react-hooks';
import { ContractModal } from '../../ContractModal/container/ContractModal';
import { getLocalCurrentFunnelGeneralInfo } from '../../Funnel/helper/funnelHelper';
import TrailBanner from '../../TrailBanner/container/TrailBanner';
import { getAuthData, isTokenValid } from '../../Login/container/Login';
import { detectSafari, detectFirefox } from '../../VoiceRecorder/helper/deviceHelper';
import * as Sentry from '@sentry/browser';
import UpvotyChangeLogsDrawer from '../components/UpvotyChangeLogsDrawer';
import Logo from '../components/Logo';
import { shouldShowOnboarding } from '../../OnBoarding/helper/onboarding-condition';
import UserAvatar from '../components/UserAvatar';
import { SidebarTourProgress } from '../../Tour/components/SidebarTourProgress';
import moment from 'moment';
import {
  selectUserData,
  setUserData,
  setUserIsInitializing,
  useSubscriptionInfo
} from '../redux/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setProductFruitsProps } from '../../ProductFruitsWrapper/redux/productFruitsSlice';
import Confetti from '../components/Confetti';
import { useDispatch, useSelector } from 'react-redux';
import ProductFruitsWrapper from '../../ProductFruitsWrapper/container/ProductFruitsWrapper';
import { Role } from '../utils/roleTypes';
import { questions } from '../../OnBoarding/utils/question-config';
import CompatibilityPopup from '../../Builder/components/sharedUI/CompatibilityPopup';
import { navigateAndPreventSameRoute } from '../../helper/navigation';
import PaymentMethodUpdateModal from '../components/PaymentMethodUpdateModal';
import mixpanel from 'mixpanel-browser';
import { GET_DASHBOARD_INFO } from '../utils/graphqlQueries';
import FunnelSearch from '../components/FunnelSearch';
import {
  setGlobalSettingsFlags,
  setFunnelViewSelection,
  selectedFunnelViewSelection,
  isFunnelRefreshLoading,
  setFunnelRefreshLoading,
  selectChallengeModuleName,
  setChallengeModulesCompleted,
  setChallengeProgressPercent
} from '../redux/uiSlice';
import HotjarWrapper from '../../Builder/DebugTracking/container/HotjarWrapper';
import InActiveUserForm from '../../Login/container/InActiveUserForm';
import LatestVersionValidator from '../../SharedUI/components/LatestVersionValidator';
import InitializeUpvoty from '../components/InitializeUpvoty';
import { FetchFunnelsBeforeComponentLoadHOC } from '../../SharedUI/components/FetchFunnelsBeforeComponentLoadHOC';
import { getEnvironment } from '../../helper/environment';
import SuspenseLoadingWrapper from '../../Share/container/SuspenseLoadingWrapper';
import { getAllRoutes, getARoute } from '../utils/routeUtils';
import { BsKanban } from 'react-icons/bs';
import { FaListUl } from 'react-icons/fa';
import { EVENT_VIEWS } from '../../Events/utils/eventViewsTypes';
import KanbanFunnelSelect from '../../Events/components/EventKanban/KanbanFunnelSelect';
import { EventsProvider } from '../../Events/context/EventsContext';
import EventFilter from '../../Events/components/Event/EventFilter';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { LocalStorageKeys } from '../../Share/constants/localstorageKeys';
import ChallengeBreadcrumb from '../components/ChallengeBreadcrumb';
import { FullVideoInterface, modules } from '../../LearningCenter/utils/videos';
import gql from 'graphql-tag';
import { getAllAddOnsThunk } from '../../Marketplace/redux/thunk';

const { Header, Content, Sider } = Layout;

const GET_LEARNING_CENTER_PROTOCOL = gql`
  query {
    getLearningCenterProtocol {
      videoId
      playedSeconds
      completed
    }
  }
`;

function UI() {
  const [compatibilityPopupVisible, setCompatibilityPopupVisible] = useState(false);
  const navigate = useNavigate();
  const funnelView = useSelector(selectedFunnelViewSelection);
  const isFunnelReloading = useSelector(isFunnelRefreshLoading);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const thunkDispatch = useDispatch();
  const challengeModule = useSelector(selectChallengeModuleName);
  const localUserData = useAppSelector(selectUserData); // nessecary to be up to date without refetch
  const { basicInfo } = getLocalCurrentFunnelGeneralInfo();
  const isAgencyCustomer = localUserData?.role == Role.AGENCY_CUSTOMER;
  const isEmployee = !!getAuthData('isEmployee');
  const hideMenuRoutes = ['/onboarding-einfuehrung', '/onboarding-fragen'];
  const hideMenu = hideMenuRoutes.includes(pathname) || isAgencyCustomer;
  const isLocal = getEnvironment().isLocal;

  const { loading: dashboardInfoLoading, error, data } = useQuery(GET_DASHBOARD_INFO);
  const loading = dashboardInfoLoading;
  const isLoggedIn = isTokenValid();
  const funnelTitle = basicInfo?.title;
  const [paymentMethodUpdateModalVisible, setPaymentMethodUpdateModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(() => {
    const storedValue = localStorage.getItem(LocalStorageKeys.SIDEBAR_VIEW);
    if (window.innerWidth < 700) return true;
    return storedValue !== null ? storedValue === 'true' : false;
  });

  const isBuilderScreen = pathname.indexOf('builder') > 0;
  const isMyFunnelsScreen = pathname.indexOf('meine-funnels') > 0;
  const routePath = getAllRoutes(routes);
  const currentRoute = routePath.find(({ path }) => pathname.includes(path));
  const topLayer = currentRoute?.topLayer || isBuilderScreen;

  const [searchParams] = useSearchParams();
  const returnToParam = searchParams.get('returnTo');

  const path = currentRoute?.path;
  const routeTitle = currentRoute?.title;
  const {
    getUser: userData,
    getPublicContract: publicContracts,
    getContract: contracts,
    getGlobalSettingsFlags: globalSettingsFlags
  } = data || {};

  const subscriptionInfo = useSubscriptionInfo();

  useEffect(() => {
    if (detectSafari() || detectFirefox()) setCompatibilityPopupVisible(true);
  }, []);

  useEffect(() => {
    if (globalSettingsFlags) dispatch(setGlobalSettingsFlags(globalSettingsFlags));
  }, [globalSettingsFlags]);

  useEffect(() => {
    if (userData) {
      Sentry?.setUser({
        id: userData?.id
      });
      Sentry.setTag('uniqueIdentifier', String(new Date().getTime()));
      dispatch(setUserData(userData));
      dispatch(getAllAddOnsThunk());

      if (isAgencyCustomer) return;

      mixpanel.identify(userData?.id);
      mixpanel.register_once({
        'User ID': userData?.id,
        onboardingQuestionsResponses: localStorage.getItem('meetovoQuestionConfig')
      });
      mixpanel.people.set({
        name: `${userData.firstName}`,
        subscription_status: subscriptionInfo.status,
        user_role: userData.role
      });
    }
  }, [userData]);

  useEffect(() => {
    if (userData && subscriptionInfo) {
      dispatch(
        setProductFruitsProps({
          subscription_status: subscriptionInfo.status,
          user_role: userData.role
        })
      );
    }
    if (subscriptionInfo?.status === 'PAUSED' && userData?.role === 'COACH')
      setPaymentMethodUpdateModalVisible(true);
  }, [subscriptionInfo, userData]);

  useEffect(() => {
    if (isAgencyCustomer || !subscriptionInfo) return;

    let script: any;
    script = document.createElement('script');
    script.setAttribute('id', 'profitwell-js');
    script.setAttribute('data-pw-auth', '9144731ae7682b7156e244b90b131b83');
    script.innerHTML = `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js'); 
      profitwell('start', { 'user_id': '${subscriptionInfo?.paddle_user_id}', 'user_email': '${userData?.email}' });`;
    document.body.appendChild(script);

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [subscriptionInfo]);

  const { data: learningProtocolData } = useQuery(GET_LEARNING_CENTER_PROTOCOL);

  useEffect(() => {
    if (modules && learningProtocolData) {
      let totalProgressPercent = 0,
        totalModulesCompleted = 0;

      modules.forEach(module => {
        let tempVideos: FullVideoInterface[] = JSON.parse(JSON.stringify(module.videos));

        tempVideos = tempVideos.map(video => {
          const thisLearningProtocol =
            learningProtocolData?.getLearningCenterProtocol?.find(
              ({ videoId }: { videoId: number }) => videoId == video.video_id
            ) || {};
          const enrichedVideo = {
            ...thisLearningProtocol,
            ...video,
            completed: thisLearningProtocol.completed
          };
          return enrichedVideo;
        });

        const tempProgress = +(
          (tempVideos.filter(v => v.completed === true).length / module.videos.length) *
          100
        ).toFixed(0);

        if (tempProgress === 100) {
          totalProgressPercent = totalProgressPercent + tempProgress;
          totalModulesCompleted++;
          dispatch(setChallengeProgressPercent((totalProgressPercent / 600) * 100));
          dispatch(setChallengeModulesCompleted(totalModulesCompleted));
        }
      });
    }
  }, [learningProtocolData]);

  if (error) {
    dispatch(setUserIsInitializing(false));
    window?.handleLogout();
    return <Alert type="error" message={UIMessages.noDashboardInfoError} banner />;
  }

  if (pathname === '/' || loading || !localUserData)
    return <LoadingOutlined className="centered" spin />;

  if (paymentMethodUpdateModalVisible) {
    return (
      <PaymentMethodUpdateModal
        visible={paymentMethodUpdateModalVisible}
        toggleModal={() => {
          setPaymentMethodUpdateModalVisible(paymentMethodUpdateModalVisible);
        }}
      />
    );
  }

  const appDiv = document.getElementsByClassName('app')[0];
  if (appDiv) {
    appDiv.setAttribute('current-route', pathname.replace('/', ''));
    appDiv.setAttribute('current-role', localUserData.role);
  }
  const registeredToday = moment(localUserData.createdAt).isSame(moment(), 'day');
  const shouldRedirectToCheckout =
    isLoggedIn &&
    ['NEEDS_TO_PAY', 'FREEZE_PLAN'].includes(subscriptionInfo.status) &&
    !path?.includes('/account') &&
    localUserData.role != Role.ADMIN &&
    localUserData.role != Role.AGENCY_CUSTOMER;
  const shouldRedirectToFirstBoarding = shouldShowOnboarding({
    isLoggedIn,
    questionsAnswered: localUserData.questionsAnswered,
    isEmployee,
    role: localUserData.role,
    pathname
  });
  const shouldRedirectToSecondBoarding =
    shouldRedirectToFirstBoarding &&
    (subscriptionInfo.status === 'ACTIVE' || subscriptionInfo.status === 'FREE_FOREVER') &&
    !registeredToday;
  const differentUIRedirect =
    shouldRedirectToCheckout || shouldRedirectToFirstBoarding || shouldRedirectToSecondBoarding;
  const shouldMountProductFruits =
    !pathname.includes('/onboarding') &&
    !pathname.includes('/checkout') &&
    !shouldRedirectToCheckout &&
    !shouldRedirectToFirstBoarding &&
    !shouldRedirectToSecondBoarding;
  const questionsAnswered = localUserData.questionsAnswered;

  const handleCloseSider = () => {
    // @ts-ignore
    document.querySelector('.ant-layout-sider-zero-width-trigger')?.click();
  };

  const redirectRuleChecker = (currentRoute: string) => {
    if (!differentUIRedirect) {
      if (
        !shouldShowOnboarding({
          isLoggedIn,
          questionsAnswered,
          isEmployee,
          role: localUserData.role,
          pathname
        }) &&
        (questionsAnswered?.length ?? 0) >= questions.length &&
        currentRoute !== '/meine-funnels'
      ) {
        switch (path) {
          case '/login':
          case '/registrieren':
          case '/onboarding-einfuehrung':
          case '/onboarding-fragen':
            navigateAndPreventSameRoute(navigate, '/meine-funnels');
        }
      }
    }
  };
  redirectRuleChecker(pathname);

  if (isAgencyCustomer) {
    navigateAndPreventSameRoute(navigate, '/kontakte');
  } else {
    if (shouldRedirectToFirstBoarding && !shouldRedirectToSecondBoarding)
      navigateAndPreventSameRoute(navigate, '/onboarding-einfuehrung');
    if (shouldRedirectToSecondBoarding) navigateAndPreventSameRoute(navigate, '/onboarding-fragen');
    if (shouldRedirectToCheckout) navigateAndPreventSameRoute(navigate, '/checkout');
  }

  const currentUserAllowedRoutes = routes.filter(({ allowedRoles }) =>
    allowedRoles.includes(localUserData?.role)
  );

  const routeChildren = routePath
    .filter(({ allowedRoles }) => allowedRoles.includes(localUserData?.role))
    .map(({ path, component }, i) => {
      return <Route key={i} path={path} element={component}></Route>;
    });

  const currentlySelectedPath = getARoute(routes, pathname);

  const toggleFunnelView = (view: string) => {
    dispatch(setFunnelViewSelection(view));
  };

  const refetchBookingInfo = () => {
    dispatch(setFunnelRefreshLoading(true));
  };

  const handleCollapse = () => {
    localStorage.setItem(LocalStorageKeys.SIDEBAR_VIEW, (!collapsed).toString());
    setCollapsed(!collapsed);
  };

  const stateClass = collapsed ? 'collapsed' : 'expanded';

  return (
    <SuspenseLoadingWrapper>
      {(localUserData?.role == Role.ADMIN || localUserData?.role == Role.SUPPORT_MANAGER) &&
        !isLocal && <InActiveUserForm />}
      <LatestVersionValidator />
      <InitializeUpvoty />
      <FetchFunnelsBeforeComponentLoadHOC>
        <Layout className={topLayer ? 'ui__top-layer' : ''}>
          {shouldMountProductFruits && (
            <>
              <ProductFruitsWrapper />
              <Confetti />
            </>
          )}
          {globalSettingsFlags && <HotjarWrapper />}
          <CompatibilityPopup
            visible={compatibilityPopupVisible}
            onClose={() => setCompatibilityPopupVisible(false)}
          />
          <ContractModal publicContracts={publicContracts} contracts={contracts} />
          {!topLayer && !hideMenu && (
            <Sider
              className="sider"
              trigger
              collapsed={collapsed}
              breakpoint="lg"
              collapsedWidth="65"
              theme="light"
              width={243}
              style={{
                height: '100vh',
                ...(path === '/kontakte' && { position: 'fixed', left: 0, bottom: 0 })
              }}
              onCollapse={() => handleCollapse}
            >
              <div className="collapse-button-container">
                <Button
                  className="collapse-button"
                  type="primary"
                  onClick={() => handleCollapse()}
                  icon={
                    collapsed ? <FaAngleRight /> : <FaAngleLeft style={{ marginLeft: '-1px' }} />
                  }
                />
              </div>
              <Logo collapsed={collapsed} isBuilderScreen />
              <Menu
                className="sidebar-menu"
                mode="inline"
                selectedKeys={[pathname]}
                defaultOpenKeys={[currentlySelectedPath?.path as string]}
              >
                {currentUserAllowedRoutes
                  .filter(({ icon, topLayer }) => !!icon && !topLayer)
                  .map(({ title, path, icon, subRoutes }) => {
                    const menuItemLabel = collapsed ? (
                      <>{icon}</>
                    ) : (
                      <span>
                        {icon}
                        <span className="nav-text">{title}</span>
                      </span>
                    );

                    return subRoutes ? (
                      <Menu.SubMenu className="sider-sub-menu" key={path} title={menuItemLabel}>
                        {subRoutes.map(subRoute => (
                          <Menu.Item onClick={handleCloseSider} key={subRoute.path}>
                            <Link to={subRoute.path}>
                              {collapsed ? (
                                subRoute.icon
                              ) : (
                                <>
                                  <span>
                                    {subRoute.icon}
                                    <span className="nav-text">{subRoute.title}</span>
                                  </span>
                                </>
                              )}
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item
                        onClick={handleCloseSider}
                        key={path}
                        title={collapsed ? title : ''}
                      >
                        <Link to={path}>{menuItemLabel}</Link>
                      </Menu.Item>
                    );
                  })}
              </Menu>
              <div className="sider__bottom">
                <SidebarTourProgress collapsed={collapsed} />
              </div>
            </Sider>
          )}
          <Layout className={isBuilderScreen ? '' : `ui__main-content ${stateClass}`}>
            {!isBuilderScreen && (
              <Header
                className={
                  isBuilderScreen ? '' : `ui__header ${stateClass} ui__sub-header-background`
                }
              >
                <div className="ui__sub-header__left">
                  {hideMenu && <Logo />}
                  {topLayer && <Logo iconOnly />}
                  {!isAgencyCustomer && (
                    <h1 className="mx-2">
                      {!hideMenu && path != '/bearbeiten' ? (
                        path?.includes('/analyse') ? (
                          funnelTitle
                        ) : challengeModule ? (
                          <ChallengeBreadcrumb moduleName={challengeModule} />
                        ) : (
                          routeTitle
                        )
                      ) : (
                        funnelTitle
                      )}
                    </h1>
                  )}
                </div>

                <div className="ui__sub-header__right">
                  {!pathname.includes('/onboarding') &&
                    !topLayer &&
                    localUserData.role !== Role.AGENCY_CUSTOMER && <TrailBanner />}

                  {path == '/meine-funnels' && (
                    <div style={{ marginRight: 12 }}>
                      <FunnelSearch />
                    </div>
                  )}

                  {!topLayer && (
                    <>
                      {path == '/kontakte' && (
                        <div className="ui__sub-header__right__funnel-icons-container">
                          <div className="funnel-icon">
                            {funnelView === EVENT_VIEWS.KANBAN ? (
                              <KanbanFunnelSelect />
                            ) : (
                              <EventFilter />
                            )}
                          </div>
                          <div className="funnel-view-icons">
                            <Tooltip title="Kanban">
                              <div
                                onClick={() => toggleFunnelView(EVENT_VIEWS.KANBAN)}
                                className={`${
                                  funnelView === EVENT_VIEWS.KANBAN
                                    ? 'agency-dashboard-brand-background-color'
                                    : 'not-selected-icon'
                                }`}
                              >
                                <BsKanban
                                  className={`${
                                    funnelView === EVENT_VIEWS.KANBAN
                                      ? ''
                                      : 'agency-dashboard-brand-color'
                                  }`}
                                  size={20}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title="Tabelle">
                              <div
                                onClick={() => toggleFunnelView(EVENT_VIEWS.LIST)}
                                className={`${
                                  funnelView === EVENT_VIEWS.LIST
                                    ? 'agency-dashboard-brand-background-color'
                                    : 'not-selected-icon'
                                }`}
                              >
                                <FaListUl
                                  className={`${
                                    funnelView === EVENT_VIEWS.LIST
                                      ? ''
                                      : 'agency-dashboard-brand-color'
                                  }`}
                                  size={20}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                      <div className="ui__sub-header__right__icons-right">
                        <UpvotyChangeLogsDrawer />
                        <UserAvatar
                          shouldShowMyAccount={
                            !isEmployee && localUserData.role !== Role.AGENCY_CUSTOMER
                          }
                        />
                      </div>
                    </>
                  )}

                  {topLayer && (
                    <CloseOutlined
                      onClick={() =>
                        navigate(`${returnToParam ? returnToParam : '/meine-funnels'}`)
                      }
                    />
                  )}
                </div>
              </Header>
            )}

            <Content
              className={`${isBuilderScreen ? '' : 'ui__content max-width__1980px-centered'} ${
                isMyFunnelsScreen ? 'p-0 overflow-y-hidden' : 'overflow-y-auto'
              }`}
            >
              <Routes>{routeChildren.map(route => route)}</Routes>
            </Content>
          </Layout>
        </Layout>
      </FetchFunnelsBeforeComponentLoadHOC>
    </SuspenseLoadingWrapper>
  );
}

export default UI;
