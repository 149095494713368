import { Funnel } from '../../interfaces/builderSliceTypes';
import { JOB_ATTRIBUTES_IDENTIFIER, requiredFieldsForJob } from './AdJobHelper';
import { TARGET_GROUP_ELEMENTS } from '../../components/generalSettings/TargetGroup/components/TargetGroupHelper';

export function validateFunnelGoogleJobPresets(funnel: Funnel) {
  return requiredFieldsForJob.some(key => {
    if (key === TARGET_GROUP_ELEMENTS.EXPERIENCE) {
      return false;
    }
    // @ts-ignore
    const value = funnel[key];
    if (Array.isArray(value)) {
      return !value.length;
    } else if (typeof value === 'object' && value !== null) {
      return !value.place;
    } else {
      return !value;
    }
  });
}

export function validateFunnelGoogleJobValues(funnel: Funnel): (keyof Funnel)[] {
  // @ts-ignore
  return requiredFieldsForJob.filter(key => {
    if (key === TARGET_GROUP_ELEMENTS.EXPERIENCE) {
      return false;
    }
    // @ts-ignore
    const value = funnel[key];
    if (Array.isArray(value)) {
      return !value.length;
    } else if (typeof value === 'object' && value !== null) {
      return !value.place;
    } else {
      return !value;
    }
  });
}

function getEnumNameForKey(key: string): string | undefined {
  const enums = { JOB_ATTRIBUTES_IDENTIFIER, TARGET_GROUP_ELEMENTS };

  for (const [enumName, enumObj] of Object.entries(enums)) {
    if (Object.values(enumObj).includes(key)) {
      return enumName;
    }
  }

  return undefined;
}

export function findEnumForFirstKey(keys: string[]): string | undefined {
  if (keys.length === 0) {
    return undefined;
  }
  return getEnumNameForKey(keys[0]);
}

export function formatNumber(value: string | number): string {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numericValue)) {
    return '';
  }
  return numericValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
