import {
  EditorState,
  Modifier,
  ContentState,
  convertToRaw,
  RawDraftContentBlock,
  convertFromRaw,
  SelectionState,
  ContentBlock
} from 'draft-js';
import { Map } from 'immutable';
import { getDraftStateFromBlockArray, DraftElementsEnum } from '../../../../helper/defaultValues';
import { AI_PROMPT_TEXT_TYPES } from '../../../../hooks/ai-conversation/getAiTextResponse';

interface SelectionRange {
  startKey: string;
  endKey: string;
  startOffset: number;
  endOffset: number;
}

function getSelectionRange(editorState: EditorState): SelectionRange {
  const selectionState = editorState.getSelection();
  const isBackward = selectionState.getIsBackward();

  return {
    startKey: isBackward ? selectionState.getFocusKey() : selectionState.getAnchorKey(),
    endKey: isBackward ? selectionState.getAnchorKey() : selectionState.getFocusKey(),
    startOffset: isBackward ? selectionState.getFocusOffset() : selectionState.getAnchorOffset(),
    endOffset: isBackward ? selectionState.getAnchorOffset() : selectionState.getFocusOffset()
  };
}

function getBlocksInRange(content: ContentState, startKey: string, endKey: string): ContentBlock[] {
  const blockMap = content.getBlockMap();
  return blockMap
    .skipUntil(block => block?.getKey() === startKey)
    .takeUntil(block => block?.getKey() === endKey)
    .concat(Map([[endKey, blockMap.get(endKey)]]))
    .toArray() as ContentBlock[];
}

function getTextSlice(
  block: ContentBlock,
  startKey: string,
  endKey: string,
  startOffset: number,
  endOffset: number
): string {
  const key = block.getKey();
  const text = block.getText();

  if (key === startKey && key === endKey) {
    return text.slice(startOffset, endOffset);
  }
  if (key === startKey) {
    return text.slice(startOffset);
  }
  if (key === endKey) {
    return text.slice(0, endOffset);
  }
  return text;
}

export function getSelectedTextInDraftJsEditorState(editorState: EditorState): string {
  const { startKey, endKey, startOffset, endOffset } = getSelectionRange(editorState);
  const content = editorState.getCurrentContent();
  const blocksInRange = getBlocksInRange(content, startKey, endKey);

  return blocksInRange.reduce((selectedText, block) => {
    return selectedText + getTextSlice(block, startKey, endKey, startOffset, endOffset);
  }, '');
}

export function getSelectedHtmlInDraftJsEditorState(editorState: EditorState): EditorState {
  const { startKey, endKey, startOffset, endOffset } = getSelectionRange(editorState);
  const content = editorState.getCurrentContent();
  const blocksInRange = getBlocksInRange(content, startKey, endKey);
  const rawContent = convertToRaw(content);

  const selectedBlocks = blocksInRange.map(block => {
    const key = block.getKey();
    const rawBlock = rawContent.blocks.find(b => b.key === key);
    return {
      key,
      text: getTextSlice(block, startKey, endKey, startOffset, endOffset),
      type: block.getType(),
      depth: block.getDepth(),
      inlineStyleRanges: rawBlock?.inlineStyleRanges || [],
      entityRanges: rawBlock?.entityRanges || [],
      data: block.getData().toObject()
    };
  });

  const rawContentState = {
    blocks: selectedBlocks as RawDraftContentBlock[],
    entityMap: rawContent.entityMap
  };

  return EditorState.createWithContent(convertFromRaw(rawContentState));
}

export function replaceTextInDraftJsEditorState(
  editorState: any,
  newText: any,
  isUsingSelectedText: boolean
) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  if (selectionState.isCollapsed() || !isUsingSelectedText) {
    const newContentState = ContentState.createFromText(newText);

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    const newSelectionState = newContentState.getSelectionAfter();
    const finalEditorState = EditorState.forceSelection(newEditorState, newSelectionState);

    return finalEditorState;
  }

  const newContentState = Modifier.replaceText(contentState, selectionState, newText);
  return EditorState.push(editorState, newContentState, 'change-block-data');
}

function htmlToArray(
  htmlString: string,
  defaultDraftElement?: DraftElementsEnum
): { type: DraftElementsEnum; text: string }[] {
  const parser = new DOMParser();
  let doc;

  if (htmlString.trim().startsWith('<')) {
    doc = parser.parseFromString(htmlString, 'text/html');
  } else {
    doc = parser.parseFromString(`<p>${htmlString}</p>`, 'text/html');
  }

  const elements = Array.from(doc.body.children);
  const resultArray: { type: DraftElementsEnum; text: string }[] = [];

  for (let element of elements) {
    let type: DraftElementsEnum;
    let text = element.textContent?.trim() || '';

    if (defaultDraftElement) {
      type = defaultDraftElement;
      resultArray.push({ type, text });
      continue;
    }

    switch (element.tagName.toLowerCase()) {
      case 'h1':
        type = DraftElementsEnum.HEADING;
        break;
      case 'h2':
        type = DraftElementsEnum.SUB_HEADING;
        break;
      case 'h4':
        type = DraftElementsEnum.SUB_HEADING;
        break;
      case 'h3':
        type = DraftElementsEnum.CAPTION;
        break;
      case 'h4':
        type = DraftElementsEnum.CAPTION;
        break;
      case 'p':
        type = DraftElementsEnum.PARAGRAPH;
        break;
      default:
        type = DraftElementsEnum.PARAGRAPH;
        break;
    }

    resultArray.push({ type, text });
  }

  return resultArray;
}

export function replaceTextInDraftJsEditorStateUsingHtml(
  editorState: EditorState,
  newHtml: string,
  isUsingSelectedText: boolean,
  questionType?: AI_PROMPT_TEXT_TYPES
) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  let defaultDraftElement;

  if (questionType === AI_PROMPT_TEXT_TYPES.HEADLINE || questionType === AI_PROMPT_TEXT_TYPES.ASK) {
    defaultDraftElement = DraftElementsEnum.SUB_HEADING;
  }
  if (
    questionType === AI_PROMPT_TEXT_TYPES.ADVANTAGES ||
    questionType === AI_PROMPT_TEXT_TYPES.TASK
  ) {
    defaultDraftElement = DraftElementsEnum.CAPTION;
  }

  const blockArray = htmlToArray(newHtml, defaultDraftElement); // Convert HTML to Draft.js block array
  // @ts-ignore
  const blocksFromHtml = convertFromRaw(getDraftStateFromBlockArray(blockArray)).getBlocksAsArray();
  const newContentState = ContentState.createFromBlockArray(blocksFromHtml);

  let newEditorState: EditorState;

  if (isUsingSelectedText && !selectionState.isCollapsed()) {
    // Replace the current selection with the new HTML content
    const updatedContentState = Modifier.replaceWithFragment(
      contentState,
      selectionState,
      newContentState.getBlockMap()
    );

    newEditorState = EditorState.push(editorState, updatedContentState, 'insert-fragment');

    // Calculate the new end offset
    let totalLengthOfNewBlocks = 0;
    blocksFromHtml.forEach(block => {
      totalLengthOfNewBlocks += block.getText().length + 1; // +1 for block separator
    });

    const startKey = selectionState.getStartKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = startOffset + totalLengthOfNewBlocks - 1; // Adjust for zero-based index

    // Create a new selection that only includes the new text
    const newSelection = new SelectionState({
      anchorKey: startKey,
      anchorOffset: startOffset,
      focusKey: startKey, // Assuming all text fits within a single block, adjust if multiple blocks
      focusOffset: endOffset,
      hasFocus: true
    });

    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
  } else {
    newEditorState = EditorState.createWithContent(newContentState);
  }

  return newEditorState;
}

export function cleanHtml(inputHtml: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputHtml, 'text/html');

  function cleanNode(node: Node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;

      element.removeAttribute('style');
      element.removeAttribute('align');
      element.removeAttribute('class');

      Array.from(element.childNodes).forEach(cleanNode);

      if (!element.innerHTML.trim()) {
        element.remove();
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      const textNode = node as Text;

      const trimmedText = textNode.nodeValue?.trim();

      if (trimmedText) {
        if (!textNode.parentElement || textNode.parentElement === doc.body) {
          const p = doc.createElement('p');
          p.textContent = trimmedText;
          textNode.replaceWith(p);
        }
      } else {
        textNode.remove();
      }
    }
  }

  Array.from(doc.body.childNodes).forEach(cleanNode);

  const cleanedHtml = Array.from(doc.body.childNodes)
    .map(node => (node as HTMLElement).outerHTML)
    .join('');

  return cleanedHtml;
}
