import { Select } from 'antd';
import React, { PropsWithChildren } from 'react';
import { SelectProps } from 'antd/lib/select';

interface CategoryPickerProps extends PropsWithChildren<SelectProps<string[]>> {
  placeholder?: string;
  value: any;
  options: any[];
}

export default function CategoryPicker({ placeholder, value, options, ...other }: CategoryPickerProps) {
  return (
    <Select
      placeholder={placeholder || ''}
      className="w-100"
      mode="multiple"
      value={value || []}
      {...other}
    >
      {(options || []).map((item: any) => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}
