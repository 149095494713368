import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { CardTypes } from '../config/builderUi';
import {
  craftToJSON,
  getEncodedBuilderPagesCalendarSettingsAndCommonElements,
  getThemeToApplyInFunnel
} from '../helper/craftJs';
import {
  BuilderPageDataType,
  BuilderState,
  DraggingElement,
  ChannelTemplatesType,
  EmailTemplateType,
  FunnelThemeType,
  GeneralSettings,
  MetaData,
  Funnel,
  OldLeadQualifier,
  OldLeadQualifierIncludingDeleteIds,
  builderCommonElementsTypes,
  EmailType,
  BuilderStateChecksums,
  SaveCompleteBuilderThunkPayloadType,
  CHANNEL_DATA_INPUTS,
  CHANNEL_TYPE,
  builderPresetElements,
  GeneralSettingsTabView,
  MessagingTemplateTabType,
  DesignSettingsTabView,
  ConnectedFonts,
  AvailableFonts,
  TargetAudience,
  CompanyDetail,
  Communication,
  JobInformation
} from '../interfaces/builderSliceTypes';
import {
  addFunnelPageThunk,
  deleteFunnelPageThunk,
  duplicatePageThunk,
  getCompleteFunnel,
  setPageCustomJs,
  updateFunnelPageThunk,
  setPageTrackingThunk,
  reOrderBuilderPagesThunk,
  setPageNameThunk,
  saveCompleteBuilderDataThunk,
  deleteUserFunnelThemeThunk,
  getAllAvailableFonts,
  createAvailableFontsThunk,
  deleteAvailableFontsThunk,
  setConnectedFontThunk,
  setCompanyJobTargetAudienceThunk
} from './thunk';
import { message } from 'antd';
import { GeneralMessages, PagesListMessages } from '../../config/messages';
import { trackInteraction } from '../DebugTracking/utils/helper';
import { defaultBuilderPages, defaultFunnelTheme, thankyouPageType } from '../helper/defaultValues';
import { suggestionModalError } from '../../Funnel/utils/suggestionModalError';
import {
  defaultCommunication,
  defaultCompanyDetail,
  defaultFunnel,
  defaultJobInformation,
  defaultTargetAudience,
  hasFalsyValue,
  hasTruthyValue,
  removeFalsyValues
} from './thunk.utils';
import { JOB_ATTRIBUTES_IDENTIFIER } from '../AdJobBuilder/helper/AdJobHelper';
import {
  buttonPresetsInitialValues,
  imageCarousalPresetsInitialValues,
  imagePresetsInitialValues,
  choicePresetsInitialValues
} from '../interfaces/presetsInititalValues';
import { BUILDER_VALIDATION_ERRORS } from '../container/BuilderValidator';
import { parseJSONFieldsWithinObject } from '../helper/sharedFunctions';

export const INITIAL_STATE: BuilderState = {
  isMobileView: true,
  isEditingColorTheme: false,
  selectedCard: null,
  selectedElementTab: '1',
  builderPages: defaultBuilderPages,
  selectedPageId: null,
  checksums: {
    builderPages: null,
    generalSettings: null,
    calendarSettings: null,
    funnelTheme: null,
    funnel: null,
    metaData: null,
    otherEmailAddressesToSend: null,
    emailTemplatesGeneralSettings: null,
    emailTemplateActiveTabContent: null,
    messagingTemplatesGeneralSettings: null,
    messagingTemplates: null,
    funnelPresets: null,
    funnelConnectedFont: null,
    communication: null,
    companyDetail: null,
    jobInformation: null,
    targetAudience: null
  },
  // always put ui related stuff in this object

  ui: {
    funnelIsEditingModalVisibility: false,
    completeFunnelSavingByUserInteractionDisabled: true,
    funnelSuggestionModalVisibility: false
  },
  commonElements: {
    header: '',
    footer: ''
  },
  metaData: {
    metaTitle: '',
    description: '',
    customMetaDataEnabled: false,
    iconURL: '',
    previewImageURL: ''
  },
  generalSettings: {
    title: '',
    uniqueIdentifier: '',
    brandingEnabled: false,
    cookieBanner: false,
    language: 'DE'
  },
  funnelTheme: defaultFunnelTheme,
  currentlyEditingTheme: defaultFunnelTheme,
  draggingElement: {
    name: '',
    nodeId: ''
  },
  channelTemplates: {
    sender: '',
    brandingEnabled: false,
    activeTab: '',
    senderName: '',
    messageNumber: '',
    phoneNumber: '',
    templates: []
  },
  aiSettingsUpdatedAt: null,
  funnel: defaultFunnel,
  communication: defaultCommunication,
  companyDetail: defaultCompanyDetail,
  jobInformation: defaultJobInformation,
  targetAudience: defaultTargetAudience,
  loaders: {
    mainFunnelLoader: true,
    isFunnelSaved: true
  },
  oldLeadQualifier: {
    leadQualifier: [],
    leadQualifierIdsToDelete: [],
    choiceIdsToDelete: []
  },
  shouldSaveFunnel: true,
  selectedBlockNodeIds: [],
  lastSaveSuccessful: true,
  userColorThemes: [],
  funnelSuggestion: '',
  funnelPresets: [],
  generalSettingsTabView: GeneralSettingsTabView.GENERAL_SETTINGS,
  designSettingsTabView: DesignSettingsTabView.COLORS,
  builderValidationErrors: [],
  availableFonts: []
};

export const builderUiSlice = createSlice({
  name: 'builderUI',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialBuilderState: state => {
      // in this case we hold the title so that the user dont see a layout transisiont
      return {
        ...INITIAL_STATE,
        generalSettings: {
          ...INITIAL_STATE.generalSettings,
          title: state.generalSettings.title
        }
      };
    },

    setMobileView: (state, action: PayloadAction<{ payload: boolean }>) => {
      state.isMobileView = action.payload.payload;
    },
    setSelectedCard: (state, action: PayloadAction<{ payload: CardTypes }>) => {
      state.selectedCard = action.payload.payload;
    },
    setSelectedElementTab: (state, action: PayloadAction<{ payload: string }>) => {
      state.selectedElementTab = action.payload.payload;
    },

    setBuilderPages: (state, action: PayloadAction<BuilderPageDataType[]>) => {
      state.builderPages = action.payload;

      trackInteraction({
        type: 'REDUX_ACTION',
        customEventName: 'SET_BUILDER_PAGES_IN_BUILDER_SLICE',
        additionalData: {
          payload: { ...action.payload }
        }
      });
    },
    editBuilderPage: (state, action: PayloadAction<BuilderPageDataType>) => {
      const index = state.builderPages.findIndex(single => single.id === action.payload.id);
      if (index < 0) return;

      if (!state.shouldSaveFunnel) return;

      state.builderPages[index] = action.payload;

      trackInteraction({
        type: 'REDUX_ACTION',
        customEventName: 'EDIT_BUILDER_PAGE_IN_BUILDER_SLICE',
        additionalData: {
          payload: { ...action.payload },
          state: { builderPages: state.builderPages }
        }
      });
    },
    setSelectedPageId: (state, action: PayloadAction<number>) => {
      state.selectedElementTab = '1';
      state.selectedPageId = action.payload;

      trackInteraction({
        type: 'REDUX_ACTION',
        customEventName: 'SET_SELECTED_PAGE_ID_IN_BUILDER_SLICE',
        additionalData: {
          state: { selectedPageId: state.selectedPageId }
        }
      });
    },
    updateCommonElements: (state, action: PayloadAction<any>) => {
      state.commonElements = { ...state.commonElements, ...action.payload };
    },
    editMetaData: (state, action: PayloadAction<MetaData>) => {
      state.metaData = { ...state.metaData, ...action.payload };
      state.loaders.isFunnelSaved = false;
    },
    editGeneralSettings: (state, action: PayloadAction<GeneralSettings>) => {
      state.generalSettings = { ...state.generalSettings, ...action.payload };
      state.loaders.isFunnelSaved = false;
    },
    setCurrentlyEditingTheme: (state, action: PayloadAction<FunnelThemeType>) => {
      state.currentlyEditingTheme = action.payload;
    },
    setConnectedFonts: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        state.funnel.connectedFonts = action.payload;
      } else {
        const filterFont = state.funnel.connectedFonts?.filter(
          font => font.type !== action.payload.type
        );
        state.funnel.connectedFonts = filterFont
          ? [...filterFont, action.payload]
          : [action.payload];
      }
    },
    setCompleteFunnelSavingByUserInteractionDisabled: (state, action: PayloadAction<boolean>) => {
      state.ui.completeFunnelSavingByUserInteractionDisabled = action.payload;
    },
    editDraggingElement: (state, action: PayloadAction<DraggingElement>) => {
      const { name, nodeId, ...rest } = action.payload;
      state.draggingElement.name = name;
      nodeId && (state.draggingElement.nodeId = nodeId);
      name === null && (state.draggingElement.nodeId = '');
      state.draggingElement = {
        ...state.draggingElement,
        ...rest
      };
    },
    // Email Template Actions

    updateChannelGeneralSettings: (
      state,
      action: PayloadAction<{ type: CHANNEL_DATA_INPUTS; value: string | boolean }>
    ) => {
      const { type, value } = action.payload;
      //@ts-ignore
      state.channelTemplates[type] = value;
      state.shouldSaveFunnel = true;
      state.loaders.isFunnelSaved = false;
    },
    switchEmailTemplateTab: (state, action: PayloadAction<string>) => {
      state.channelTemplates.activeTab = action.payload;
      state.loaders.isFunnelSaved = false;
    },
    editFunnelSavedIndicator: (state, action: PayloadAction<boolean>) => {
      state.loaders.isFunnelSaved = action.payload;
    },
    setShouldSaveFunnel: (state, action: PayloadAction<boolean>) => {
      state.shouldSaveFunnel = action.payload;
    },
    updateEmailTemplate: (state, action: PayloadAction<EmailTemplateType>) => {
      const activeIndex: number = +state.channelTemplates.activeTab;
      state.channelTemplates.templates[activeIndex].emailTemplate = action.payload;
      state.shouldSaveFunnel = true;
      state.loaders.isFunnelSaved = false;
    },
    toggleChannelEnable: state => {
      const activeIndex: number = +state.channelTemplates.activeTab;
      state.channelTemplates.templates[activeIndex].channelEnabled = !state.channelTemplates
        .templates[activeIndex].channelEnabled;
      state.shouldSaveFunnel = true;
      state.loaders.isFunnelSaved = false;
    },
    updateMessageSendingChannel: (state, action: PayloadAction<CHANNEL_TYPE>) => {
      const activeIndex: number = +state.channelTemplates.activeTab;
      state.channelTemplates.templates[activeIndex].sendingChannel = action.payload;
      state.shouldSaveFunnel = true;
      state.loaders.isFunnelSaved = false;
    },
    updateEmailTemplateByType: (
      state,
      action: PayloadAction<{ type: EmailType; template: EmailTemplateType }>
    ) => {
      const activeIndex: number = state.channelTemplates.templates.findIndex(
        ({ emailTemplate }) => emailTemplate.type === action.payload.type
      );
      state.channelTemplates.templates[activeIndex].emailTemplate = action.payload.template;
      state.shouldSaveFunnel = true;
      state.loaders.isFunnelSaved = false;
    },
    setFunnelIsEditingModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.ui.funnelIsEditingModalVisibility = action.payload;
    },
    setMainFunnelLoader: (state, action: PayloadAction<boolean>) => {
      state.loaders.mainFunnelLoader = action.payload;
    },
    setSelectedBlockNodeIds: (state, action: PayloadAction<string>) => {
      if (state.selectedBlockNodeIds.indexOf(action.payload) === -1) {
        state.selectedBlockNodeIds = [...state.selectedBlockNodeIds, action.payload];
      } else {
        state.selectedBlockNodeIds = state.selectedBlockNodeIds?.filter(
          el => el !== action.payload
        );
      }
    },
    resetSelectedBlockNodeIds: state => {
      state.selectedBlockNodeIds = [];
    },
    setIsEditingColorTheme: (state, action: PayloadAction<boolean>) => {
      state.isEditingColorTheme = action.payload;
    },
    setIsFunnelSaved: (state, action: PayloadAction<boolean>) => {
      state.loaders.isFunnelSaved = action.payload;
    },
    addNewUserColorTheme: (state, action: PayloadAction<FunnelThemeType>) => {
      state.userColorThemes = [...state.userColorThemes, action.payload];
    },
    updateUserColorTheme: (state, action: PayloadAction<FunnelThemeType>) => {
      state.userColorThemes = state.userColorThemes.map((theme: FunnelThemeType) => {
        if (theme.id === action.payload.id) {
          return { ...theme, ...action.payload };
        }

        return theme;
      });
    },
    setUserColorTheme: (state, action: PayloadAction<FunnelThemeType[]>) => {
      state.userColorThemes = [...state.userColorThemes, ...action.payload];
    },

    setFunnelTheme: (state, action: PayloadAction<FunnelThemeType>) => {
      state.funnelTheme = action.payload;
    },
    setFunnelSuggestionModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.ui.funnelSuggestionModalVisibility = action.payload;
    },
    setJobInformation: (
      state,
      action: PayloadAction<{ identifier: keyof JobInformation; value: any }>
    ) => {
      const { identifier, value } = action.payload;
      state.loaders.isFunnelSaved = false;

      state.jobInformation = {
        ...(state.jobInformation || {}),
        [identifier]: value
      };
    },
    setCompanyDetail: (
      state,
      action: PayloadAction<{ identifier: keyof CompanyDetail; value: any }>
    ) => {
      const { identifier, value } = action.payload;
      state.loaders.isFunnelSaved = false;
      state.companyDetail = {
        ...(state.companyDetail || {}),
        [identifier]: value
      };
    },
    setTargetAudience: (
      state,
      action: PayloadAction<{ identifier: keyof TargetAudience; value: any }>
    ) => {
      const { identifier, value } = action.payload;
      state.loaders.isFunnelSaved = false;
      state.targetAudience = {
        ...(state.targetAudience || {}),
        [identifier]: value
      };
    },
    setCommunication: (
      state,
      action: PayloadAction<{ identifier: keyof Communication; value: any }>
    ) => {
      state.loaders.isFunnelSaved = false;
      const { identifier, value } = action.payload;
      state.communication = {
        ...(state.communication || {}),
        [identifier]: value
      };
    },
    setGeneralSettingsTabView: (state, action: PayloadAction<GeneralSettingsTabView>) => {
      state.generalSettingsTabView = action.payload;
    },
    setDesignSettingsTabView: (state, action: PayloadAction<DesignSettingsTabView>) => {
      state.designSettingsTabView = action.payload;
    },
    setElementsPresets: (
      state,
      action: PayloadAction<{
        elementKey: builderPresetElements;
        updatedValue: any;
      }>
    ) => {
      const { elementKey, updatedValue } = action.payload;

      const index = state.funnelPresets.findIndex((pre: any) => pre.presetType === elementKey);

      const exists = index !== -1;

      const newPreset = {
        presetType: elementKey,
        preset: {
          ...(exists
            ? { ...state.funnelPresets[index].preset, ...updatedValue }
            : { ...updatedValue })
        }
      };

      if (exists) {
        state.funnelPresets[index] = newPreset;
      } else {
        state.funnelPresets.push(newPreset);
      }
      state.loaders.isFunnelSaved = false;
    },
    updateMessageTemplates: (state, action: PayloadAction<MessagingTemplateTabType>) => {
      const activeIndex: number = +state.channelTemplates.activeTab;
      state.channelTemplates.templates[activeIndex].messagingTemplate = action.payload;
      state.shouldSaveFunnel = true;
      state.loaders.isFunnelSaved = false;
    },
    addBuilderValidationError: (state, action: PayloadAction<BUILDER_VALIDATION_ERRORS>) => {
      if (!state.builderValidationErrors.includes(action.payload)) {
        state.builderValidationErrors = [...state.builderValidationErrors, action.payload];
      }
    },
    removeBuilderValidationError: (state, action: PayloadAction<BUILDER_VALIDATION_ERRORS>) => {
      state.builderValidationErrors = state.builderValidationErrors.filter(
        error => error !== action.payload
      );
    }
  },
  extraReducers: {
    //@ts-ignore
    [deleteFunnelPageThunk.rejected]: (state: any, action: any) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [deleteFunnelPageThunk.pending]: (state: any, action: any) => {
      if (!state.shouldSaveFunnel) return;

      const { id } = action.meta.arg;
      state.loaders.mainFunnelLoader = true;
      state.loaders.isFunnelSaved = false;
      state.builderPages = state.builderPages.filter((page: any) => page.id !== id);
      if (state.selectedPageId === id) {
        state.selectedPageId = state.builderPages[0].id;
      }

      trackInteraction({
        type: 'REDUX_EXTRA_REDUCER',
        customEventName: 'DELETE_FUNNEL_PAGE_THUNK_PENDING_IN_BUILDER_SLICE',
        additionalData: {
          builderPages: state.builderPages
        }
      });
    },
    //@ts-ignore
    [deleteFunnelPageThunk.fulfilled]: (state: any, action: PayloadAction<string | number>) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [addFunnelPageThunk.pending]: (state: any, action: PayloadAction) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [addFunnelPageThunk.rejected]: (state: any, action: PayloadAction) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [addFunnelPageThunk.fulfilled]: (state: any, action: PayloadAction<BuilderPageDataType>) => {
      if (!state.shouldSaveFunnel) return;

      const thankYouPage: BuilderPageDataType | undefined = state.builderPages.find(
        (page: BuilderPageDataType) => page.type === thankyouPageType
      );
      state.builderPages = [
        ...state.builderPages.filter((page: BuilderPageDataType) => page.type !== thankyouPageType),
        { ...action.payload },
        ...(thankYouPage ? [{ ...thankYouPage }] : [])
      ];
      state.selectedPageId = action.payload.id;
      state.loaders.mainFunnelLoader = false;

      trackInteraction({
        type: 'REDUX_EXTRA_REDUCER',
        customEventName: 'ADD_FUNNEL_PAGE_THUNK_FULFILLED_IN_BUILDER_SLICE',
        additionalData: {
          builderPages: state.builderPages
        }
      });
    },
    //@ts-ignore
    [duplicatePageThunk.pending]: (state: any, action: PayloadAction) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [duplicatePageThunk.rejected]: (state: any, action: PayloadAction) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [duplicatePageThunk.fulfilled]: (
      state: any,
      action: PayloadAction<{ page: BuilderPageDataType }>
    ) => {
      if (!state.shouldSaveFunnel) return;

      const thankYouPage: BuilderPageDataType | undefined = state.builderPages.find(
        (page: BuilderPageDataType) => page.type === thankyouPageType
      );
      state.builderPages = [
        ...state.builderPages.filter((page: BuilderPageDataType) => page.type !== thankyouPageType),
        { ...action.payload.page },
        ...(thankYouPage ? [{ ...thankYouPage }] : [])
      ];

      // disabled because of overwriting issues
      // state.selectedPageId = action.payload.page.id;

      message.success(PagesListMessages.duplicatedSuccessfully, 5);

      state.loaders.mainFunnelLoader = false;

      trackInteraction({
        type: 'REDUX_EXTRA_REDUCER',
        customEventName: 'DPLICATE_PAGE_THUNK_FULFILLED_IN_BUILDER_SLICE',
        additionalData: {
          builderPages: state.builderPages
        }
      });
    },
    //@ts-ignore
    [setPageTrackingThunk.pending]: (
      state: BuilderState,
      action: { meta: { arg: BuilderPageDataType } }
    ) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;
      const payload = action.meta.arg;

      const pageIndex = state.builderPages.findIndex(({ id }) => id === payload.id);
      state.builderPages[pageIndex] = { ...state.builderPages[pageIndex], ...payload };

      trackInteraction({
        type: 'REDUX_EXTRA_REDUCER',
        customEventName: 'SET_PAGE_TRACKING_THUNK_FULFILLED_IN_BUILDER_SLICE',
        additionalData: {
          builderPages: state.builderPages
        }
      });
    },
    //@ts-ignore
    [setPageTrackingThunk.rejected]: (state: any, action: PayloadAction) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [setPageTrackingThunk.fulfilled]: (state: any, action: PayloadAction<BuilderPageDataType>) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [setPageCustomJs.pending]: (
      state: BuilderState,
      action: { meta: { arg: BuilderPageDataType } }
    ) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;
      const payload = action.meta.arg;

      const pageIndex = state.builderPages.findIndex(({ id }) => id === payload.id);
      state.builderPages[pageIndex].customJs = payload.customJs;
    },
    //@ts-ignore
    [setPageCustomJs.rejected]: (state: any, action: PayloadAction) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [setPageCustomJs.fulfilled]: (state: any, action: PayloadAction<BuilderPageDataType>) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [setPageNameThunk.pending]: (
      state: BuilderState,
      action: { meta: { arg: BuilderPageDataType } }
    ) => {
      if (!state.shouldSaveFunnel) return;

      const payload = action.meta.arg;

      const pageIndex = state.builderPages.findIndex(({ id }) => id === payload.id);
      state.builderPages[pageIndex].name = payload.name;
    },
    //@ts-ignore
    [setPageNameThunk.rejected]: (state: any, action: PayloadAction) => {
      message.error(PagesListMessages.renameError);
    },
    //@ts-ignore
    [setPageNameThunk.fulfilled]: (state: any, action: PayloadAction<BuilderPageDataType>) => {},
    //@ts-ignore
    [updateFunnelPageThunk.pending]: (
      state: BuilderState,
      action: { meta: { arg: BuilderPageDataType } }
    ) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;
      const payload = action.meta.arg;

      const pageIndex = state.builderPages.findIndex(({ id }) => id === payload.id);
      state.builderPages[pageIndex] = { ...state.builderPages[pageIndex], ...payload };

      trackInteraction({
        type: 'REDUX_EXTRA_REDUCER',
        customEventName: 'UPDATE_FUNNEL_PAGE_TRACKING_THUNK_FULFILLED_IN_BUILDER_SLICE',
        additionalData: {
          builderPages: state.builderPages
        }
      });
    },
    //@ts-ignore
    [updateFunnelPageThunk.rejected]: (state: any, action: PayloadAction) => {
      if (!state.shouldSaveFunnel) return;
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [updateFunnelPageThunk.fulfilled]: (state: any, action: PayloadAction<BuilderPageDataType>) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = false;
    },

    // @ts-ignore
    [getCompleteFunnel.fulfilled]: (
      state: BuilderState,
      action: PayloadAction<{
        commonElements: builderCommonElementsTypes;
        pages: BuilderPageDataType[];
        generalSettings: GeneralSettings;
        funnelTheme: FunnelThemeType;
        funnel: Funnel;
        metaData: MetaData;
        channelTemplates: ChannelTemplatesType;
        oldLeadQualifier: OldLeadQualifier[];
        isCalendarEnabled: boolean;
        isContactFormEnabled: boolean;
        isFileUploaderEnabled: boolean;
        checksums: BuilderStateChecksums;
        userColorThemes: FunnelThemeType[];
        selectedPageId?: number;
        funnelPresets: any[];
        aiSettingsUpdatedAt: Date;
        targetAudience: TargetAudience;
        companyDetail: CompanyDetail;
        communication: Communication;
        jobInformation: JobInformation;
      }>
    ) => {
      state.loaders.mainFunnelLoader = false;

      state.companyDetail = action.payload.companyDetail;
      state.communication = action.payload.communication;
      state.jobInformation = action.payload.jobInformation;
      state.targetAudience = action.payload.targetAudience;

      // Builder funnel presets
      state.funnelPresets = action.payload?.funnelPresets;

      // Builder Pages
      state.commonElements = action.payload.commonElements;

      // Builder Pages
      state.builderPages = action.payload.pages;
      state.selectedElementTab = '1';
      const defaultJobGeneralSettings = {
        jobTitle: '',
        companyName: '',
        category: [],
        employmentType: [],
        baseSalary: [0, 1000],
        address: {
          place: '',
          zoom: 12,
          lat: 39.1731621,
          lng: -77.2716502
        },
        salaryPlan: 'Monatlich',
        workplaceModel: ''
      };
      state.selectedPageId = action.payload.pages[0].id;
      state.metaData = action.payload.metaData;

      let parsedJobData: any = {};

      Object.values(JOB_ATTRIBUTES_IDENTIFIER).forEach((key: any) => {
        const value = action.payload.funnel[key as keyof Funnel];
        if (value !== null) {
          parsedJobData[key] = typeof value === 'string' ? JSON.parse(value) : value;
        } else {
          parsedJobData[key] =
            defaultJobGeneralSettings[key as keyof typeof defaultJobGeneralSettings];
        }
      });

      state.aiSettingsUpdatedAt = action.payload?.aiSettingsUpdatedAt;

      state.funnel = {
        ...state.funnel,
        ...action.payload.funnel,
        ...parsedJobData
      };

      // Funnel Theme
      state.funnelTheme = action.payload.funnelTheme;
      state.currentlyEditingTheme = action.payload.funnelTheme;

      state.channelTemplates = action.payload.channelTemplates;

      state.generalSettings = action.payload.generalSettings;

      state.oldLeadQualifier = {
        leadQualifier: action.payload.oldLeadQualifier,
        leadQualifierIdsToDelete: [],
        choiceIdsToDelete: []
      };
      if (action.payload.selectedPageId) {
        state.selectedPageId = action.payload.selectedPageId;
      }
      state.checksums = action.payload.checksums;
      state.userColorThemes = action.payload.userColorThemes;
    },
    // @ts-ignore
    [getCompleteFunnel.rejected]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = false;
      message.error(GeneralMessages.error);
    },
    // @ts-ignore
    [getCompleteFunnel.pending]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [reOrderBuilderPagesThunk.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<BuilderPageDataType[]>
    ) => {
      if (!state.shouldSaveFunnel) return;
      state.loaders.mainFunnelLoader = false;
      state.builderPages = payload;
    },
    //@ts-ignore
    [reOrderBuilderPagesThunk.pending]: (state: BuilderState) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [reOrderBuilderPagesThunk.rejected]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [saveCompleteBuilderDataThunk.pending]: (
      state: BuilderState,
      action: { meta: { arg: SaveCompleteBuilderThunkPayloadType | undefined } }
    ) => {
      if (!state.shouldSaveFunnel) return;

      state.loaders.mainFunnelLoader = true;

      if (action.meta?.arg?.shouldOnlySaveEmailTemplates) return;

      const {
        builderPages,
        header,
        footer
      } = getEncodedBuilderPagesCalendarSettingsAndCommonElements({
        builderPages: state.builderPages,
        selectedPageId: state.selectedPageId,
        oldTheme: state.funnelTheme,
        newTheme: state.currentlyEditingTheme,
        isEditingColorTheme: state.isEditingColorTheme
      });
      state.builderPages = builderPages;

      state.commonElements = {
        header: header,
        footer: footer
      };
    },
    //@ts-ignore
    [saveCompleteBuilderDataThunk.rejected]: (state: BuilderState, action: PayloadAction) => {
      compareAndApplyUpdatedFunnelTheme({
        builderPages: state.builderPages,
        selectedPageId: state.selectedPageId as number,
        isEditingColorTheme: state.isEditingColorTheme
      });
      state.lastSaveSuccessful = false;
      state.loaders.isFunnelSaved = false;
      state.loaders.mainFunnelLoader = false;

      const themeToApply = getThemeToApplyInFunnel({
        oldTheme: state.funnelTheme,
        newTheme: state.currentlyEditingTheme
      });
      state.currentlyEditingTheme = themeToApply;
      state.funnelTheme = themeToApply;
      state.isEditingColorTheme = false;
      const suggestionError = suggestionModalError(action.payload);
      if (suggestionError) {
        const { message: suggestion } = suggestionError;
        state.ui.funnelSuggestionModalVisibility = true;
        state.funnelSuggestion = suggestion;
      }
    },
    //@ts-ignore
    [saveCompleteBuilderDataThunk.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<{ checksums: BuilderStateChecksums }>
    ) => {
      compareAndApplyUpdatedFunnelTheme({
        builderPages: state.builderPages,
        selectedPageId: state.selectedPageId as number,
        isEditingColorTheme: state.isEditingColorTheme
      });
      if (!state.shouldSaveFunnel) return;

      state.checksums = { ...state.checksums, ...payload.checksums };

      state.lastSaveSuccessful = true;
      state.loaders.isFunnelSaved = true;
      state.loaders.mainFunnelLoader = false;
      state.isEditingColorTheme = false;
      const themeToApply = getThemeToApplyInFunnel({
        oldTheme: state.funnelTheme,
        newTheme: state.currentlyEditingTheme
      });
      state.currentlyEditingTheme = themeToApply;
      state.funnelTheme = themeToApply;
    },
    //@ts-ignore
    [deleteUserFunnelThemeThunk.pending]: (state: BuilderState) => {},
    //@ts-ignore
    [deleteUserFunnelThemeThunk.rejected]: (state: BuilderState) => {
      state.loaders.isFunnelSaved = false;
      state.isEditingColorTheme = false;
    },
    //@ts-ignore
    [deleteUserFunnelThemeThunk.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<FunnelThemeType>
    ) => {
      state.loaders.isFunnelSaved = true;
      state.currentlyEditingTheme = state.funnelTheme;
      state.userColorThemes = state.userColorThemes.filter(({ id }) => {
        return payload.id !== id;
      });
      state.isEditingColorTheme = false;
    },
    //@ts-ignore
    [getAllAvailableFonts.pending]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [getAllAvailableFonts.rejected]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [getAllAvailableFonts.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<AvailableFonts[]>
    ) => {
      state.loaders.mainFunnelLoader = false;
      state.availableFonts = [...state.availableFonts, ...payload];
    },
    //@ts-ignore
    [createAvailableFontsThunk.pending]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [createAvailableFontsThunk.rejected]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [createAvailableFontsThunk.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<AvailableFonts>
    ) => {
      state.loaders.mainFunnelLoader = false;
      if (payload) {
        state.availableFonts = [...state.availableFonts, payload];
      }
    },
    //@ts-ignore
    [deleteAvailableFontsThunk.pending]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [deleteAvailableFontsThunk.rejected]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [deleteAvailableFontsThunk.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<number>
    ) => {
      state.loaders.mainFunnelLoader = false;
      state.availableFonts = state.availableFonts.filter(font => font.id !== payload);
    },
    //@ts-ignore
    [setConnectedFontThunk.pending]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = true;
    },
    //@ts-ignore
    [setConnectedFontThunk.rejected]: (state: BuilderState) => {
      state.loaders.mainFunnelLoader = false;
    },
    //@ts-ignore
    [setConnectedFontThunk.fulfilled]: (
      state: BuilderState,
      { payload }: PayloadAction<ConnectedFonts[]>
    ) => {
      state.loaders.mainFunnelLoader = false;
      state.funnel = { ...state.funnel, connectedFonts: payload };
    },
    //@ts-ignore
    [setCompanyJobTargetAudienceThunk.fulfilled]: (
      state: BuilderState,
      {
        payload
      }: PayloadAction<{
        jobInformation: JobInformation;
        companyDetail: CompanyDetail;
        targetAudience: TargetAudience;
        communication: Communication;
      }>
    ) => {
      state.jobInformation = payload.jobInformation;
      state.companyDetail = payload.companyDetail;
      state.targetAudience = payload.targetAudience;
      state.communication = payload.communication;
    }
  }
});

export const {
  setIsEditingColorTheme,
  setMobileView,
  setSelectedCard,
  setSelectedElementTab,
  editBuilderPage,
  setBuilderPages,
  setSelectedPageId,
  updateCommonElements,
  editMetaData,
  editGeneralSettings,
  editDraggingElement,
  toggleChannelEnable,
  updateEmailTemplate,
  updateMessageSendingChannel,
  updateEmailTemplateByType,
  switchEmailTemplateTab,
  editFunnelSavedIndicator,
  setShouldSaveFunnel,
  setMainFunnelLoader,
  setFunnelIsEditingModalVisibility,
  setSelectedBlockNodeIds,
  resetSelectedBlockNodeIds,
  setCurrentlyEditingTheme,
  setCompleteFunnelSavingByUserInteractionDisabled,
  resetToInitialBuilderState,
  setIsFunnelSaved,
  addNewUserColorTheme,
  updateUserColorTheme,
  setFunnelSuggestionModalVisibility,
  setUserColorTheme,
  setFunnelTheme,
  setJobInformation,
  setCompanyDetail,
  setTargetAudience,
  setCommunication,
  updateChannelGeneralSettings,
  // setJobGeneralSettings,
  // setTargetGroupGeneralSettings,
  // setAiDataGeneralSettings,
  setGeneralSettingsTabView,
  setDesignSettingsTabView,
  setElementsPresets,
  updateMessageTemplates,
  addBuilderValidationError,
  removeBuilderValidationError,
  setConnectedFonts
} = builderUiSlice.actions;

export const selectMobileView = (state: RootState) => {
  return state.builderUI.isMobileView;
};

export const useFunnelIsEditingModalVisibility = () => {
  return useAppSelector((state: RootState) => state.builderUI.ui.funnelIsEditingModalVisibility);
};

export const useBuilderPages = () => {
  const builderPages = useAppSelector((state: RootState) =>
    state.builderUI.builderPages.filter(page => page.type !== thankyouPageType)
  );
  return builderPages;
};

export const useAllBuilderPages = () =>
  useAppSelector((state: RootState) => state.builderUI.builderPages);

export const useAllPages = () => {
  const builderPages = useAppSelector((state: RootState) => state.builderUI.builderPages);
  return builderPages;
};

export const useThankYouPage = () => {
  const thankYouPage = useAppSelector((state: RootState) =>
    state.builderUI.builderPages.find(page => page.type === thankyouPageType)
  );
  return thankYouPage;
};

export const useBuilderSelectedPageId = () => {
  return useAppSelector((state: RootState) => state.builderUI.selectedPageId);
};

export const useBuilderSelectedPageData = () => {
  return useAppSelector((state: RootState) =>
    state.builderUI.builderPages.find(item => item.id === state.builderUI.selectedPageId)
  );
};

export const useCommonElements = () => {
  return useAppSelector((state: RootState) => state.builderUI.commonElements);
};

export const useBuilderGeneralSettings = (): GeneralSettings => {
  return useAppSelector((state: RootState) => state.builderUI.generalSettings);
};

export const useFunnelTheme = (): FunnelThemeType => {
  return useAppSelector((state: RootState) => state.builderUI.funnelTheme);
};

export const useDraggingElement = (): string | null => {
  return useAppSelector((state: RootState) => state.builderUI.draggingElement.name);
};

export const useDraggingElementNodeId = (): string | undefined => {
  return useAppSelector((state: RootState) => state.builderUI.draggingElement.nodeId);
};

export const useDraggingElementDetail = (): DraggingElement => {
  return useAppSelector((state: RootState) => state.builderUI.draggingElement);
};

export const useChannelTemplatesData = (): ChannelTemplatesType => {
  return useAppSelector((state: RootState) => state.builderUI.channelTemplates);
};

export const useOldLeadQualifier = (): OldLeadQualifierIncludingDeleteIds => {
  return useAppSelector((state: RootState) => state.builderUI.oldLeadQualifier);
};

export const useFunnel = (): Funnel => {
  return useAppSelector((state: RootState) => state.builderUI.funnel);
};

export const useCommunication = (): Communication => {
  return useAppSelector((state: RootState) => ({
    ...state.builderUI.communication
  }));
};

export const useCompanyDetail = (): CompanyDetail => {
  return useAppSelector((state: RootState) => ({
    ...state.builderUI.companyDetail
  }));
};

export const useJobInformation = (): JobInformation => {
  return useAppSelector((state: RootState) => ({
    ...state.builderUI.jobInformation
  }));
};

export const useTargetAudience = (): TargetAudience => {
  return useAppSelector((state: RootState) => ({
    ...state.builderUI.targetAudience
  }));
};

export const useEmptyGeneralSettings = (
  tabsToExclude: (GeneralSettingsTabView | null)[] = []
): GeneralSettingsTabView[] =>
  useAppSelector((state: RootState) => {
    const excludeCommunication = tabsToExclude?.includes(GeneralSettingsTabView.COMMUNICATION);
    const excludeCompanyInfo = tabsToExclude?.includes(GeneralSettingsTabView.COMPANY_INFORMATION);
    const excludeJobInfo = tabsToExclude?.includes(GeneralSettingsTabView.JOB_INFORMATION);
    const excludeTargetGroup = tabsToExclude?.includes(GeneralSettingsTabView.TARGET_GROUP);
    const isJobInfoFalsy = hasFalsyValue(state.builderUI.jobInformation || {});
    const isCompanyDetailFalsy = hasFalsyValue(state.builderUI.companyDetail || {});
    const isTargetAudienceFalsy = hasFalsyValue(state.builderUI.targetAudience || {});
    const isCommunicationFalsy = hasFalsyValue(state.builderUI.communication || {});
    let emptySettings = [];
    if (isTargetAudienceFalsy && !excludeTargetGroup)
      emptySettings.push(GeneralSettingsTabView.TARGET_GROUP);
    if (isCompanyDetailFalsy && !excludeCompanyInfo)
      emptySettings.push(GeneralSettingsTabView.COMPANY_INFORMATION);
    if (isCommunicationFalsy && !excludeCommunication)
      emptySettings.push(GeneralSettingsTabView.COMMUNICATION);
    if (isJobInfoFalsy && !excludeJobInfo)
      emptySettings.push(GeneralSettingsTabView.JOB_INFORMATION);
    return emptySettings;
  });

export const useIsAiSettingDuplicated = (): boolean =>
  useAppSelector((state: RootState) => {
    const { salary, salaryPlan, address, ...restJobInfo } = state.builderUI.jobInformation || {};
    const { experienceDuration, ...restTargetInfo } = state.builderUI.targetAudience || {};
    const aiRelatedData = {
      ...restJobInfo,
      ...restTargetInfo,
      ...state.builderUI.companyDetail,
      ...state.builderUI.communication
    };
    return false;
  });

export const useCompleteBuilderState = (): BuilderState => {
  return useAppSelector((state: RootState) => state.builderUI);
};

// Loaders
export const useMainFunnelLoader = (): boolean => {
  return useAppSelector((state: RootState) => state.builderUI.loaders.mainFunnelLoader);
};

export const useIsFunnelSaved = (): boolean => {
  return useAppSelector((state: RootState) => state.builderUI.loaders.isFunnelSaved);
};

export const useLastSaveSuccessful = (): boolean => {
  return useAppSelector((state: RootState) => state.builderUI.lastSaveSuccessful);
};

export const useShouldSaveFunnel = (): boolean => {
  return useAppSelector((state: RootState) => state.builderUI.shouldSaveFunnel);
};

export const useSelectedBlockNodeIds = (): any[] => {
  return useAppSelector((state: RootState) => state.builderUI.selectedBlockNodeIds);
};

export const useCurrentlyEditingTheme = () => {
  return useAppSelector((state: RootState) => state.builderUI.currentlyEditingTheme);
};

export const useCompleteFunnelSavingByUserInteractionDisabled = () => {
  return useAppSelector(
    (state: RootState) => state.builderUI.ui.completeFunnelSavingByUserInteractionDisabled
  );
};

export const useIsEditingColorTheme = () => {
  return useAppSelector((state: RootState) => state.builderUI.isEditingColorTheme);
};

export const useUserColorThemes = () => {
  return useAppSelector((state: RootState) => state.builderUI.userColorThemes);
};

export const useCurrentTemplate = () => {
  const { templates, activeTab } = useAppSelector(
    (state: RootState) => state.builderUI.channelTemplates
  );
  return templates[parseInt(activeTab)];
};
export const useIsMobileView = () => {
  return useAppSelector((state: RootState) => state.builderUI.isMobileView);
};

export const useFunnelSuggestionModalVisibility = () => {
  return useAppSelector((state: RootState) => state.builderUI.ui.funnelSuggestionModalVisibility);
};

export const useFunnelSuggestion = () => {
  return useAppSelector((state: RootState) => state.builderUI.funnelSuggestion);
};

export const useGeneralSettingsTabView = () => {
  return useAppSelector((state: RootState) => state.builderUI.generalSettingsTabView);
};

export const useDesignSettingsTabView = () => {
  return useAppSelector((state: RootState) => state.builderUI.designSettingsTabView);
};

export const useFunnelAvailableFonts = () => {
  return useAppSelector((state: RootState) => state.builderUI.availableFonts);
};

export const useConnectedFonts = () => {
  return useAppSelector((state: RootState) => state.builderUI.funnel.connectedFonts);
};

export const useBuilderValidatorErrors = () => {
  return useAppSelector((state: RootState) => state.builderUI.builderValidationErrors);
};
export const useElementsPresets = (elementKey: builderPresetElements): any => {
  return useAppSelector((state: RootState) => {
    const initialPresets = {
      [builderPresetElements.BUTTON]: buttonPresetsInitialValues,
      [builderPresetElements.IMAGE]: imagePresetsInitialValues,
      [builderPresetElements.CAROUSEL]: imageCarousalPresetsInitialValues,
      [builderPresetElements.CHOICE]: choicePresetsInitialValues
    };

    const initialCurrentPresets = initialPresets[elementKey];
    const preset = state.builderUI.funnelPresets?.find((pre: any) => pre.presetType === elementKey)
      ?.preset;

    return { ...initialCurrentPresets, ...preset };
  });
};

export default builderUiSlice.reducer;

const compareAndApplyUpdatedFunnelTheme = ({
  isEditingColorTheme,
  builderPages,
  selectedPageId
}: {
  builderPages: BuilderPageDataType[];
  selectedPageId: number;
  isEditingColorTheme: boolean;
}) => {
  if (!isEditingColorTheme) {
    return;
  }
  const selectedPage = builderPages.find(({ id }) => id === selectedPageId) as BuilderPageDataType;

  window.craftJsActions?.history.ignore().deserialize(craftToJSON(selectedPage.craftState));
};
