import { Alert, Card, Col, Row, Slider, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { FaTextHeight } from 'react-icons/fa';
import EmojiPicker from '../../../GeneralComponents/EmojiPicker';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import FontStyleButtons from '../../../SharedUI/components/FontStyleButtons';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import { getKeyByValue, getValueByKey } from '../../helper/craftJs';
import { insertCharacterToEditor } from '../../helper/sharedFunctions';
import { FontAvailableSizes, defaultFontSize } from '../../interfaces/FontSizeInterface';
import { useEditor, useNode } from '@craftjs/core';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { EditorState, RichUtils } from 'draft-js';
import { RichTextComponentMessages } from '../../../config/messages';
import AiTextSettings from './RichTextComponent/components/AiTextSettings';
import { isWidgetBuilderPath } from '../../WidgetsBuilder/helper/helper';
import { useSearchParams } from 'react-router-dom';
import { RICH_TEXT_SETTINGS_VALUE } from '../../helper/richTextHelpers';

const { TabPane } = Tabs;

const RichTextSetting = () => {
  const {
    actions: { setProp },
    props,
    nodeId
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();
  const marks = {
    1: 'S',
    2: 'M',
    3: 'L',
    4: 'XL'
  };

  const themeColors = useCurrentlyEditingBuilderTheme();
  const [emojiPickerCollapsed, setEmojiPickerCollapsedState] = useState(true);
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('elementSubSettings');
  const [selectedElementTab, setSelectedElementTab] = useState(
    paramValue || RICH_TEXT_SETTINGS_VALUE.AI
  );

  const { editorState, styles } = props;

  const onChange = (editorState: EditorState) => {
    actions.history.throttle().setProp(nodeId, (props: any) => (props.editorState = editorState));
  };

  function _toggleInlineStyle(inlineStyle: string) {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  function _toggleBlockType(blockType: string) {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  }
  const selection = editorState.getSelection();
  const currentStyle = editorState.getCurrentInlineStyle();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const editorStateRef = useRef(editorState);

  const isWidgetBuilder = isWidgetBuilderPath();

  const enableAiText = !isWidgetBuilder;

  const handleChangeTab = (value: string) => {
    setSelectedElementTab(value);
  };

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  useEffect(() => {
    setSelectedElementTab(
      paramValue === RICH_TEXT_SETTINGS_VALUE.AI
        ? RICH_TEXT_SETTINGS_VALUE.AI
        : RICH_TEXT_SETTINGS_VALUE.GENERAL
    );
  }, [paramValue]);

  return (
    <>
      <Card className="settings-card settings-card__no-padding" title="Text" bordered={false}>
        <Tabs
          className="tab-container-style builder__settings-sidebar__container"
          activeKey={selectedElementTab}
          onChange={handleChangeTab}
        >
          <TabPane
            tab="Formatierung"
            className="single-tab-container"
            key={RICH_TEXT_SETTINGS_VALUE.GENERAL}
          >
            <Col span={24} className="builder__settings-sidebar__container my-3 px-4">
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Schriftgröße" icon={<FaTextHeight />} />
                  <Slider
                    className="builder-slider-style"
                    marks={marks}
                    min={1}
                    max={4}
                    step={1}
                    tooltipVisible={false}
                    defaultValue={defaultFontSize.key}
                    value={
                      getKeyByValue(
                        styles?.fontSize?.current(editorState)?.split('em')[0] || '1',
                        FontAvailableSizes
                      ).key
                    }
                    onChange={(value: any) => {
                      let newEditorState = styles.fontSize.toggle(
                        editorState,
                        `${getValueByKey(value, FontAvailableSizes).value}em`
                      );
                      onChange(newEditorState);
                    }}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Ausrichtung" />
                  <TextAlignmentButtons
                    align={blockType}
                    disabled={props.alignmentDisabled}
                    onChange={_toggleBlockType}
                  />
                </Col>
                <Col span={11} offset={2}>
                  <BuilderSettingsTitle title="Style" />
                  <FontStyleButtons
                    hasStyle={style => currentStyle.has(style)}
                    onChange={_toggleInlineStyle}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Farbe" />
                  <BuilderColorPickerButton
                    color={styles.color.current(editorState) || themeColors.textColor}
                    onChange={(textColor: any) => {
                      const newEditorState = styles.color.toggle(editorState, textColor);
                      onChange(newEditorState);
                    }}
                  />
                </Col>
                <Col span={11} offset={2}></Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle
                    title="😊 Emoji"
                    onCollapseToggleClick={value => setEmojiPickerCollapsedState(value)}
                    collapsed={emojiPickerCollapsed}
                  />
                  {!emojiPickerCollapsed && (
                    <EmojiPicker
                      height={'500px'}
                      emojiSize={30}
                      onChange={value => {
                        setProp(
                          (props: any) =>
                            (props.editorState = insertCharacterToEditor(
                              value,
                              editorStateRef.current
                            ))
                        );
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <Alert
                    message={RichTextComponentMessages.changeFontFamilyInDesignSettings}
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane tab="Text KI" key={RICH_TEXT_SETTINGS_VALUE.AI}>
            {enableAiText && <AiTextSettings editorState={editorState} />}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default RichTextSetting;
